.addNewEventModal {
	box-sizing: border-box;
	font-size: 14px;
	color: #3c3c3b;

	.modal-dialog {
		max-width: 620px;
		transform: translate(0%, 50%) !important;
	}

	&-header {
		margin: 32px 64px 0 32px;
	}

	&-close {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 17px;
		height: 17px;
		color: #111111;
	}

	&-body {
		margin: 24px 32px 0px 32px;
		display: flex;
		flex-direction: column;
	}

	&-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		padding-bottom: 32px;
		background: #fff;

		&--wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 0 16px;
		}
	}

	.react-datepicker {
		width: 328px !important;
	}

	.react-datepicker__input-container input {
		font-size: 14px !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}

	.react-datepicker-ignore-onclickoutside {
		border-color: #3db28c !important;
	}

	.react-datepicker-ignore-onclickoutside:focus {
		outline-color: #3db28c !important;
	}

	.custom-dropdown {
		// max-width: 100% !important;
		font-size: 14px !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}

	.custom-dropdown button {
		font-size: 14px !important;
		justify-content: space-between !important;
		width: 100% !important;
	}

	.datePickerCalendar {
		height: 18px;
		width: 20px;
		position: absolute;
		right: 10px;
		top: 32px;
	}

	.addFormInput {
		font-size: 14px !important;
	}
}
