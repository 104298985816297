@import '../../../css/variables';

.save-modal-preferences {
	width: 65% !important;
	max-width: none !important;
}

.saved-preferences-cancel {
	color: $indigo !important;
	border: none !important;
	cursor: pointer;
	background: $gray-200 !important;
	font-size: 14px !important;
}

.saved-preference-modal-footer {
	padding: 15px 5px !important;
}

.delete-button {
	width: 80px !important;
	margin-right: 8px;
	font-size: 14px !important;
}

.view-button {
	font-size: 14px !important;
}

.saved-card {
	padding: 12px 16px;
	margin-bottom: 8px;
}

.saved-card-selected {
	padding: 12px 16px 24px;
	margin-bottom: 8px;
	border: 2px solid $teal;
}

.saved-card-click {
	cursor: pointer;
}

.save-tabsBackground {
	border-top: 1px solid $gray-200;
}

.save-searchTabsHolder {
	margin: 0 auto;
}

.saved-preferences-tabs {
	justify-content: center;
}

.saved-filter-chip {
	display: inline-block !important;
}

.save-searchterm {
	display: inline !important;
}
