@import '../../css/mixins';
@import '../../css/variables';

.logoWidth {
	@include mq('tablet-small', max) {
		width: 20% !important;
	}
	overflow: hidden;
}

.collectionCreatedDate {
	margin-top: 24px;
	text-align: center;
}

.resource-card {
	margin-top: 16px;
	margin-bottom: 48px;
}

.relatedResourcesRectangle {
	width: 100%;
	box-shadow: 1px 1px 3px 0 #00000017;
	background-color: $white;
}

.relatedObjectRectangle {
	width: 100%;
	background-color: $white;
	border: none;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.privatePublicDisplay {
	background-color: $gray-e;
	height: 32px;
	width: 93px;
	border-radius: 4px;
	padding: 4px 8px;
	@include mq('tablet') {
		float: right;
	}
}

.privatePublicDisplayCol {
	@include mq('tablet', max) {
		display: flex;
		justify-content: center;

		margin-top: 8px;
	}
}

.alignSuper {
	vertical-align: super;
}

.collectionHeader {
	width: 100%;
	box-shadow: 1px 1px 3px 0 #00000017;
	background-color: $white;
	padding: 16px 24px 24px 24px;
}

.collectionKeywords {
	margin-top: 8px;
	text-align: center;
}

#imageToolTip {
	position: absolute;
	width: 326px;
	height: 164px;
	left: -50%;
	top: 50%;
}

#defaultCollectionImage {
	width: 342px !important;
	height: 132px !important;
	background-image: linear-gradient(124deg, #50b996, #485ea7);
}

#collectionImage {
	width: 342px !important;
	height: 132px !important;
	background-size: cover;
	background-position: center;
}

.entity-deleted {
	height: 40px;
	background-color: $gray-pale;
	width: 100%;
	padding-left: 12px;
	margin-top: 8px;
	margin-bottom: 8px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.entity-deleted-edit {
	height: 40px;
	background-color: $gray-pale;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.collectionsSearchBar {
	display: inline-block;
	border-radius: 4px;
	border: solid 2px #e2e2e2 !important;
	padding: 5px;
	width: 100%;
}

#collectionsSearchBarInput {
	display: inline-block;
	width: 90%;
	font-size: 16px;
	color: $gray-800;
	outline: none;
	border: 0;
}

.collectionsSorting {
	width: 100%;
	height: 100%;
	text-align: left !important;
}
.dropdown-toggle::after {
	float: right;
	margin-top: 3%;
}

.sorting-dropdown.dropdown {
	height: 100%;
}
