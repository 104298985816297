@import './variables';

.active-group {
    background: #f6f7f8;
}

.active-group:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    height: 100%;
    width: 4px;
    background: $teal;
}

.questionset-heading {
    display: flex;

    h4 {
        flex-grow: 1;
    }

    .question-switch {
        display: flex;
        align-items: center;
        margin: 0 30px 0 0;
        font-size: 14px;
        color: #53575a;

        label {
            display: flex;
            align-items: center;
            margin: 0 8px 0 0;
        }

        i {
            margin-left: 8px;
            color: #384b91;
            font-size: 16px;
        }
    }
}

.question-wrap {
    position: relative;
    margin-bottom: 0;
    padding: 3px 29px 3px 25px;

    textarea {
        min-height: 155px;
    }

    .question-icons {
        position: absolute;
        right: 10px;
        top: 50%;
    }

    &-parent {
        .question-icons {
            top: 52px;
        }
    }

    .action-control {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        padding: 8px 0px;
        position: absolute;
        top: -8px;
        right: 25px;
        cursor: pointer;
        min-width: 32px;
        height: 32px;
        transition: visibility 0.55s linear, opacity 0.35s ease-in-out;

        & > i {
            padding: 0 5px;
        }
    }

    .toolTip {
        position: relative;
        display: inline-block;
        padding: 0 5px;
    }

    .toolTipHidden {
        position: relative;
        display: inline-block;
        padding: 0 5px;
        visibility: hidden;
        opacity: 0;
    }

    &:hover {
        background: #f6f7f8;

        > .action-control {
            background: $white;
            box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03);

            > .toolTipHidden {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .question-icon {
        display: flex;
        align-items: center;

        .question-switch label {
            margin-bottom: 0;
        }
    }
}

.question-wrap-conditional {
    padding: 0;
}

.question-field {
    .question-icon {
        align-items: flex-start;

        .fa-lock {
            margin-top: 30px;
        }

        .question-switch {
            margin-top: 33px;
            display: inline-block;
        }
    }

    .question-icons {
        bottom: 19px;
        top: auto;
    }
}
