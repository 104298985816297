@import '../../../../css/variables';
@import '../../../../css/typography';

.doubleDropdownCustom .chip {
    font-size: 13px;
    background-color: $gray-light;
    padding: 4px 8px;
    border-radius: 16px;
    margin: 2px;

    svg {
        width: 10px;
        height: 10px;
        fill: $indigo;
        margin-left: 10px;
    }

    &:hover {
        cursor: pointer;
    }
}
