@import '../../../../css/variables';

.workflowIcon {
	padding-right: 32px !important;
}

.workflowSelectButton {
	height: 40px !important;
	width: 71px;
	border: 2px solid $teal !important;
	border-radius: 4px !important;
	background-color: $white;
	color: $indigo;
	font-family: museo-sans-rounded;
	font-size: 14px !important;
	float: right;
}

.workflowSelectedButton {
	height: 40px !important;
	width: 103px;
	border: 2px solid $teal !important;
	border-radius: 4px !important;
	background-color: $teal;
	color: $white;
	font-family: museo-sans-rounded;
	font-size: 14px !important;
	float: right;
	padding-top: 0px !important;
	border: 0px !important;
}

.workflowChevron {
	height: 24px;
	width: 24px;
}

.pad-left-16 {
	padding-left: 16px;
}

.toggleHeader {
	color: $gray-600;
	font-size: 14px;
}

.toggleContent {
	font-size: 14px;
}

.accordionToggle {
	width: 100%;
	padding-top: 10px !important;
	border-style: solid !important;
	border-width: 1px;
	padding-bottom: 2px !important;
}

.gray-top-border {
	border-top-color: $gray-200 !important;
	border-left-color: $white;
	border-right-color: $white;
	border-style: solid !important;
	border-bottom-color: $white !important;
	border-width: 1px;
}

.gray-bottom-border {
	border-top-color: $white !important;
	border-left-color: $white;
	border-right-color: $white;
	border-style: solid !important;
	border-bottom-color: $gray-200 !important;
	border-width: 1px;
}

.workFlowCheckIcon {
	padding-top: 0;
	transform: translate(-5px, 5px);
}

.assignNotify {
	float: right !important;
	display: flex;
	flex-flow: row nowrap;
	position: fixed;
	left: 2px;
	bottom: 1px;
	width: 99.6%;
	height: 71px;
	background-color: $white;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.09);
	z-index: 1000;
}

.assignNotifyAction {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex: 1 0 0;
	margin-left: auto;
	margin-right: 24px;
}

.assignWorkflowCard {
	width: -webkit-fill-available;
}
