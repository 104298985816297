@import '../../css/variables';

.login-modal {
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999 !important;

	.modal-dialog {
		margin: 10% auto 0 auto;
	}

	.modal-content {
		max-width: 600px;
		margin: 0 auto;
		-webkit-animation-duration: 0.4s;
		animation-duration: 0.4s;
	}
}

.authorCardHolder {
	padding: 0px 15px;
}

.authorCard {
	height: 72px;
	padding: 16px 0px;
	line-height: 1.3;
	margin-bottom: 16px;
	border: 1px solid $gray-300;
	background-color: #ffffff;
	@media (max-width: $bp-small) {
		margin-bottom: 8px;
		text-overflow: ellipsis;
	}
}

.footerBottom {
	justify-content: space-between;
	display: flex;
	position: relative;
	width: 100%;
	background-color: #29235c !important;
	background-image: none;
	padding: 49px 169px 41px 170px;
}

.footerButtonContainer {
	display: flex;
	flex-direction: column;
	margin-left: 16px;
}

.row-links {
	display: flex;
	flex-wrap: wrap;
	column-gap: 16px;
	margin-top: 22px;
	margin-bottom: 12px;
}

.footer-copyright-notice {
	opacity: 0.7;
}

.footerButton {
	width: 135px;
	min-height: 40px;
	border-radius: 4px;
	border: solid 2px #ffffff;
	font-size: 14px;
	display: inline-block;
	line-height: 40px;
	text-align: center;
}

.footerButton:hover {
	color: white !important;
}

@media (max-width: 1200px) {
	.footerBottom {
		padding: 49px 74px 41px 74px;
	}
}

@media (max-width: 900px) {
	.footerBottom {
		padding: 24px 32px 32px 32px;
	}
}

@media (max-width: 500px) {
	.footerBottom {
		justify-content: flex-start;
		display: flex;
		flex-direction: column;
		padding: 29px 100px 32px 24px;
	}

	.row-links {
		flex-direction: column;
	}

	.footerButtonContainer {
		margin-left: 0px;
		margin-top: 13px;
	}

	#main {
		padding-bottom: 500px;
	}
}

@media (max-width: 575px) {
	.reset-margin-mobile {
		margin: 0;
		text-align: end;
	}
}

.footerLogo {
	width: 132px;
	height: 47px !important;
}

.openAthensButton {
	width: 200px;
	background: $purple;
	color: white;
	padding: 8px 20px;
	border-radius: 2px;
	display: block;
	margin: 0 auto;
	position: relative;
}

.openAthensButton:hover {
	text-decoration: none;
	background: $purple;
	color: white;
}

.showAllLoginOptionsButton {
	width: 250px;
	background: $purple;
	color: white;
	padding: 8px 20px;
	border-radius: 2px;
	display: block;
	margin: 0 auto;
	text-align: center;
	font-size: 14px;
}

.dateHolder {
	text-align: right;
	padding-right: 25px;
}

.loginImageOff,
.loginButtonHolder:hover .loginImageOn {
	display: none;
	margin-left: auto;
	margin-right: auto;
	width: 200px;
	z-index: 9999;
}
.loginImageOn,
.loginButtonHolder:hover .loginImageOff {
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	width: 200px;
	z-index: 9999;
}

.uploader-container {
	display: inline-block;
}

.uploader-chip {
	display: flex;
	align-items: center;
	background-color: #e5f7f1;
	padding: 2px 12px;
	border-radius: 16px;
	margin-bottom: 4px;
	color: $gray-800;
	white-space: nowrap;
}

.uploader-avatar {
	margin-right: 8px;
	padding: 1px 0px;
}
