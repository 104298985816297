@import '../../css/variables';
@import '../../css/typography';

.modal {
	p,
	li {
		@include font-source(14px, $gray-dark);
	}
}

.advancedSearchRequestAccessModal {
	box-sizing: border-box;

	.modal-dialog {
		max-width: 620px;
		transform: translate(0%, 50%) !important;
	}

	&-header {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
		padding: 32px 32px 0 32px;

		&--wrap {
			padding-bottom: 12px;

			p {
				margin-bottom: 0;
				color: $gray-800;
				font-size: 14px;
			}
		}
	}

	&-head {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin-bottom: 16px;

		&--close {
			width: 20px;
			height: 20px;
			fill: $indigo;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&-body {
		width: 100%;
		padding: 12px 32px 16px 32px;

		label {
			display: flex;
			width: 100%;
			flex-flow: row nowrap;
			justify-content: space-between;
			margin: 0;
		}

		&--item {
			padding: 16px;

			h2 {
				margin-bottom: 10px;
			}
		}
	}

	&-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		background: $white;

		&--wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 0 32px 32px 32px;

			button {
				margin-left: 8px;
			}
		}
	}
}
