.backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 2000;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	transition: 0.3s ease-in-out;
}
