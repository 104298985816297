@import '../../../css/variables';
@import '../../../css/typography';

.sla {
	margin: 0;
	padding: 3px 12px;
	border-radius: 15px;
	@include font-source(14px, $white, 300);
	margin: 0 4px;
	display: flex;
	align-items: center;

	&-default {
		@extend .sla;
		@include font-source(14px, $gray-800, 300);
		background-color: $gray-light;
	}

	&-amber {
		@extend .sla;
		background-color: $orange-light;
		color: $orange-dark !important;

		.sla-icons {
			color: $orange-dark !important;
		}
	}

	&-indigo {
		@extend .sla;
		background-color: $indigo;
	}

	&-green {
		@extend .sla;
		background-color: $green-light;
		color: $green-dark !important;
	}

	&-red {
		@extend .sla;
		background-color: $red-rejected;
	}

	&-gray {
		@extend .sla;
		background-color: $gray-presubmission;
	}

	&-white {
		@extend .sla;
		background-color: $white;
		color: $gray-800 !important;
	}

	&-icons {
		display: inline-flex;

		svg,
		img {
			height: 1em;
		}
	}
}
