@import '../../../../css/variables';
@import '../../../../css/typography';

.mr-auto.navbar-nav {
    width: 100% !important;
}
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: static !important;
    }
}

.doubleDropdownCustom .dropdown-menu.show {
    border: none !important;
    margin-left: -14px !important;
    margin-right: -11px !important;
}

.doubleDropdownCustom .dropdown-toggle {
    background: none !important;
    border: 2px solid rgba(0, 0, 0, 0.15) !important;
    margin-left: -14px;
    width: 200px;
    height: 42px;
}

.doubleDropdownCustom .dropdown-toggle:after {
    display: none !important;
}

.doubleDropdownCustom .dropdown-toggle:focus,
.doubleDropdownCustom .dropdown-toggle:active {
    border: none !important;
}

.doubleDropdownCustom .nested-dropdown:after {
    display: none !important;
    border: none !important;
}

.doubleDropdownCustom .nested-dropdown {
    cursor: pointer;
    border: none !important;
}

.doubleDropdownCustom .nest-dropdown-arrow {
    cursor: pointer;
    position: static;
    margin-left: 100%;
}

.doubleDropdownCustom .main-dropdown-arrow {
    cursor: pointer;
    position: static;
    margin-top: -25px;
    padding-top: -20px;
    float: right;
}

.doubleDropdownCustom .nested-dropdown-whole {
    margin-left: 24px;
}

.doubleDropdownCustom .nested-dropdown-menu {
    border: none !important;
    box-shadow: none !important;
}

.doubleDropdownCustom .dropdown-toggle:empty::after,
.doubleDropdownCustom .dropdown-toggle::after {
    color: $indigo !important;
}

.doubleDropdownCustom .double-dropdown-input,
.doubleDropdownCustom .nested-dropdown {
    color: $gray-800 !important;
}

.doubleDropdownCustom .flip180 {
    transform: rotateX(180deg);
    margin-top: -20px;
}

.doubleDropdownCustom .dropdown {
    width: 100% !important;
}

.doubleDropdownCustom .double-dropdown-input {
    width: 105% !important;
    text-align: left !important;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.doubleDropdownCustom .double-dropdown-input:focus.btn-primary:focus,
.doubleDropdownCustom .double-dropdown-input:focus.btn-primary.focus {
    box-shadow: 0 0 0 2px #3db28c !important;
}

.doubleDropdownCustom .selected-options-container {
    min-height: 22px;
    display: flex !important;
    flex-wrap: wrap;
}

.doubleDropdownCustom .dropdown-item.active,
.doubleDropdownCustom .dropdown-item:active {
    color: $gray-900 !important;
    background-color: white !important;
}

.doubleDropdownCustom .dropdown-item:hover {
    background-color: $gray-light !important;
}

.doubleDropdownCustom .nested-dropdown-menu {
    width: 95% !important;
}

.doubleDropdownCustom .nested-dropdown-menu:focus.btn-primary:focus.btn-primary.focus,
.doubleDropdownCustom .nested-dropdown-menu:focus.btn-primary.focus.btn-primary.focus,
.doubleDropdownCustom .nested-dropdown-menu.btn-primary:focus,
.doubleDropdownCustom .nested-dropdown-menu:focus.btn:focus {
    box-shadow: none !important;
}

.doubleDropdownCustom .btn:focus {
    box-shadow: none !important;
}
