@import '../../../css/variables';
@import '../../../css/mixins';

.collectionCard {
	width: 100%;
	min-height: 332px;
	box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03);
	background-color: #ffffff;
	transform: translate(-8px, -8px);
}

.collectionCardHolder {
	width: 100%;
	transform: translate(8px, 8px);
}

.collectionCardContent {
	padding: 16px;
}

.defaultCollectionCardImage {
	width: 100%;
	height: 200px;
	background-image: linear-gradient(124deg, #50b996, #485ea7);
}

.collectionCardImage {
	width: 100%;
	height: 200px;
	background-size: cover;
	background-position: center;
}

.collectionBadge {
	margin: 16px;
}

.collectionEntityCount {
	position: absolute;
	bottom: 16px;
	right: 16px;
}

.collectionBackgroundCard {
	width: 95%;
	height: 8px;
	box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03);
	background-color: #ffffff;
	margin: auto;
	transform: translate(-8px, -8px);
	margin-bottom: 24px;
}
