/**
 * [Global Variables]
 *  
 * - Generic Colours
 * - Fonts
 */

// Generic colors
$purple: #475da7;
$purple-blue: #4c62a9;
$green: #2c8267;
$green-dark: #113328;
$green-light: #8ed4be;
$teal: #3db28c;
$light-teal: #e5f7f1;
$indigo: #29235c;
$yellow: #f0bb24;
$red-light: #f8d7db;
$red-rejected: #c2303d;
$red: #dc3645;
$dark-red: #721c24;
$deep-red: #a82935;
$gray-light: #f3f3f3;
$gray-pale: #e7e9ed;
$gray-200: #eeeeee;
$gray-300: #e2e2e2;
$gray-400: #d0d3d4;
$gray-500: #b3b8bd;
$gray-600: #868e96;
$gray-700: #53575a;
$gray-700-alt: #848e97;
$gray-800: #3c3c3b;
$gray-900: #262626;
$gray-presubmission: #6c757e;
$gray-deep: #454443;
$gray-dark: #2d2d2d;
$gray-3a: #3a3a3a;
$gray-e: #e0e3e1;
$soft-black: #222222;
$med-black: #333333;
$dark: #232f34;
$orange-light: #f9b475;
$orange-dark: #472505;
$duck-egg: #addad9;
$gold: #ffc40c;
$platinum: #4682b4;
$orange: #e07410;
$silver: #4bd0ea;
$white: #fff;
$black: #2d2d2d;
$background: #f6f7f8;
$plus-minus: #979797;
$deep-black: #000000;

// Fonts
$heading-font-family: 'museo-sans-rounded';
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

// screen
$bp-largest: 1200px;
$bp-large: 1100px;
$bp-medium: 900px;
$bp-small: 600px;
$bp-smallest: 500px;
$bp-xs: 420px;
