@import '../../../css/variables';

.mainTeamCard {
	border-top: 1px solid #e7e9ed !important;
}

.teamSlide {
	margin-bottom: 16px;
}

.teamAnswer {
	margin-left: 29px;
}

.teamQuestion {
	margin-left: 0px;
	margin-top: 12px;
	margin-bottom: 12px;
}

.teamRectangle {
	width: 100%;
	box-shadow: 1px 1px 3px 0 #00000017;
	background-color: $white;
	padding: 24px;
	padding-bottom: 8px;
}

.teamSubtitle {
	padding-bottom: 8px;
}
