@import '../../../../css/variables';

.wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 15px 0;
    border-bottom: 1px solid $gray-pale;
}

.column {
    padding: 0 10px;
}

.header {
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid $gray-pale;
}

.file-table {
    display: grid;
    grid-template-columns: 30% 35% 1fr;
    padding: 15px 0;
    border-bottom: 1px solid $gray-pale;
    grid-gap: 10px;
    &:first-child {
        margin-top: 20px;
        padding: 10px 0;
        border-top: 1px solid $gray-pale;
    }
}

.files {
    .files-header {
        h3 {
            padding: 0;
        }
        h4 {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &-area {
        margin-top: 35px;
    }
}

.all {
    display: flex;
    flex-flow: row;
    align-items: center;

    &-files {
        &-file {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            svg {
                margin-right: 20px;
            }

            &--meta {
                display: flex;
                flex-flow: column;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &-desc {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        &-user {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
        }

        &-download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 15px;
            background-color: $gray-200;
            cursor: pointer;

            svg {
                width: 16px;
                height: 16px;
                fill: $indigo;
            }
        }

        &-spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
        }
    }
}

.upload {
    display: flex;
    flex-flow: row;
    align-items: center;

    &-files {
        position: relative;

        &:first-child {
            margin-top: 20px;
        }

        &-file {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            overflow: hidden;

            svg {
                margin-right: 20px;
            }

            &--meta {
                display: flex;
                flex-flow: column;
            }
        }

        &-desc {
            position: relative;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;

            &--control {
                width: 100%;
                display: flex;
                flex-flow: row;
                input {
                    width: 97%;
                    margin-right: auto;
                }
            }

            &--wrap {
                display: flex;
                flex-flow: column;
                width: 100%;
            }
        }

        &-footer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            padding: 15px 0;
        }
    }
}

.loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;

    &-inner {
        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        img {
            margin-bottom: 10px;
        }
    }
}

.cancel {
    button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        svg {
            margin-right: 10px;
            width: 10px;
            height: 10px;
        }
    }
}

.no-files {
    margin-top: 20px;
    padding: 20px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.all-files-user {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 0.5em;
}
