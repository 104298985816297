@import '../../../../css/variables';
@import '../../../../css/typography';

.missingFieldsModal {
	box-sizing: border-box;

	.modal-dialog {
		max-width: 395px;
		transform: translate(0%, 50%) !important;
	}

	&-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: 32px 32px 0 32px;

		h1 {
			width: 90%;
		}

		&--close {
			position: absolute;
			top: 17px;
			right: 25px;
			width: 16px;
			height: 16px;
			fill: $indigo;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&-body {
		width: 100%;
		padding: 5px 32px 40px 32px;
		@include font-source(14px, $gray-800, $font-weight-semibold);
	}
}
