.smallFormInput {
	width: 100%;
	max-width: 480px;
}

.setHeight {
	height: 40px;
}

.setWidth {
	width: 123px;
}
