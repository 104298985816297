.dataUseGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dataUseDetails {
    grid-column: 1 / -1;
}

.dataUseGridItem {
    border-bottom: 1px solid #e7e9ed;
    padding: 11px 12px 11px 12px;
}

.uploadDataTitle {
    padding-bottom: 24px;
    border-bottom: 1px solid #e7e9ed;
}

.dataUseDetailsGrid {
    display: grid;
    grid-template-columns: 300px 1fr;
}

.dataUseDetailsGridItem {
    border-bottom: 1px solid #e7e9ed;
    padding: 10px 12px 10px 12px;
    color: #3c3c3b !important;
    font-size: 14px;
}

.dataUseDetailsGridHeader {
    padding: 10px 12px 10px 66px;
    font-size: 13px;
    border-bottom: 1px solid #e7e9ed;
    color: #3c3c3b;
}

.dataUseDetailsGridSection {
    padding: 20px 12px 10px 66px;
    grid-column: 1 / -1;
}

.dataUseSubHeader {
    color: #3c3c3b !important;
    font-size: 14px;
}

.dataUseLoading {
    position: absolute;
    right: 50%;
    opacity: 1 !important;
}

.opacity {
    opacity: 0.7;
}

.duplicate-data-use {
    font-size: 14px !important;
}

.data-use-link {
    color: #475da7 !important;
}

.data-use-namedDataset {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
