@import '../../../css/variables';
@import '../../../css/typography';
@import '../../../css/mixins';

.actionMenuDropdown {
    position: relative;
    left: -87px !important;
    width: max-content !important;
    max-height: 314px !important;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.09), -3px -3px 4px 0 rgba(0, 0, 0, 0.03) !important;
    background-color: $white !important;
    padding: 20px 16px !important;

    &__start {
        left: 0 !important;
    }
}

.actionMenuHeader {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;
    padding: 0 0 8px 0;
}

.actionMenuSection {
    position: relative;

    .description {
        padding: 0 0 4px 0;
        display: block;
        line-height: 14px;
    }

    .detailedDescription {
        padding: 4px 0 0 0;
        display: block;
        line-height: 13px;
    }

    .option {
        padding: 8px 0;

        .title {
            padding: 0 0 4px 0;
            display: block;
            line-height: 14px;
        }

        .description {
            padding: 4px 0 0 0;
            display: block;
            line-height: 13px;
        }
    }

    .option:last-child {
        padding: 8px 0 0 0;
    }
}

.actionMenuSection:not(:first-child) {
    padding-top: 32px;

    &:after {
        position: absolute;
        top: 16px;
        left: -16px;
        height: 1px;
        background: $gray-pale;
        width: calc(100% + 32px);
        z-index: 1000;
        content: '';
    }
}
