@import '../../../../css/variables';

.textarea {
	display: block;
	width: 100%;
	overflow: hidden;
	resize: both;
	min-height: 40px;
	line-height: 20px;
}

.textarea[contenteditable]:empty::before {
	content: 'Placeholder still possible';
	color: gray;
}

.messageFormInput {
	display: inline !important;
	max-width: 300px !important;
	width: 300px !important;
	margin: 0px !important;
}

.messageFormSend {
	display: inline !important;
	max-width: 100px !important;
	width: 100px !important;
	margin: 0px !important;
}

.messages-form {
	display: inline-flex;
	align-items: flex-end;
	width: -webkit-fill-available;
	width: -moz-available;
	margin-bottom: 0px;
	padding-top: 4px;
	background-color: $white;
}

.messages-textarea {
	display: inline-flex;
	width: 100%;
	padding-right: 8px;
	padding-left: 15px;
}

.messages-textarea2 {
	display: inline-flex !important;
}

.messages-button {
	margin-right: 15px;
}

.messages {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.message-sent {
	display: flex;
	justify-content: flex-end;
	margin: 8px 15px 8px 35px;
}

.message-received {
	display: flex;
	justify-content: flex-start;
	margin: 8px 35px 8px 15px;
}

.message-bubble {
	padding: 10px;
	border-radius: 5px;
	max-width: 90%;
	width: 90%;
	overflow-wrap: break-word;
}

.message-bubble-sent {
	background: $background;
}
.message-bubble-received {
	background: $light-teal;
}

.notes-bubble-sent {
	background-color: $white;
}

.message-metadata {
	color: $gray-700-alt;
	padding-bottom: 8px;
	span:nth-child(1) {
		font-weight: bold;
	}
	span:nth-child(2) {
		float: right;
	}
}

.info-msg {
	color: $gray-700;
	display: flex;
	justify-content: center;
	padding: 24px;
	text-align: center;
	align-items: center;
}

.info-note {
	color: $gray-700;
	display: flex;
	justify-content: center;
	padding: 24px;
	text-align: center;
	align-items: center;
	background-color: $background;
}

.no-messages {
	padding-top: 15px;
	padding-bottom: 50px;
}

.textarea-darpanel {
	min-height: 54px
}
