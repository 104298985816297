@import '../../css/variables';
@import '../../css/mixins';

.pillDivider {
	width: 1px;
	height: 25px;
	background-color: $gray-400;
	padding: 0px;
	margin: 0px;
}

.smallFormInput {
	width: 100%;
	max-width: 480px;
	height: 44px;
}

.emptySmallFormInput,
.emptySmallFormInput:focus {
	@include rounded(4px);
	border: solid 2px $red !important;
}

.setHeight {
	height: 40px;
}

.setWidth {
	width: 123px;
}

.main-body {
	margin-top: 0px !important;
}

.main-accordion-body {
	padding-bottom: 10px !important;
}

.button-tertiary:disabled {
	opacity: 0.2;
}
