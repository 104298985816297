@import '../../../../css/variables';
@import '../../../../css/typography';

.modal {
	p,
	li {
		@include font-source(14px, $gray-dark);
	}
}

.ds-search {
	position: relative;
	border-radius: 4px;
	border: solid 2px #d0d3d4;
	height: min-content !important;
	min-height: 40px;
}

.ds-search:focus-within {
	border: solid 2px #3db28c !important;
}

.ds-search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}

.ds-search .ds-search-input {
	padding-left: 30px !important;
}

.selectDatasetModal {
	box-sizing: border-box;

	.modal-dialog {
		max-width: 620px;
		transform: translate(0%, 50%) !important;
	}

	&-header {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
		padding: 32px 32px 0 32px;
		margin-bottom: 24px;

		&--wrap {
			padding-bottom: 12px;

			p {
				margin-bottom: 0;
				color: $gray-800;
				font-size: 14px;
			}
		}

		&-description {
			font-size: 14px !important;
			color: #3c3c3b;
			padding-right: 32px;
		}
	}

	&-head {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin-bottom: 16px;

		&--close {
			position: absolute;
			top: 16px;
			right: 16px;
			width: 16px;
			height: 16px;
			fill: $indigo;
			&:hover {
				cursor: pointer;
			}
		}
	}

	&-body {
		width: 100%;
		padding: 0px 32px 0px 32px;

		label {
			display: flex;
			width: 100%;
			flex-flow: row nowrap;
			justify-content: space-between;
			margin: 0;
		}

		&--item {
			padding: 16px;

			h2 {
				margin-bottom: 10px;
			}
		}
	}

	&-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		background: $white;

		&--wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 16px 32px 32px 32px;

			button {
				margin-left: 8px;
			}
		}
	}
}
